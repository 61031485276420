<template>
  <div class="footer-wrap">
    <div class="footer">
      <div class="footer_top">
        <div class="footer_top_left">
          <div class="footer_title"><img src="//file.dhunting.com/official_website/v1/logo_down.png" alt=""></div>
          <div class="qscode_wrap">
            <div class="qscode_item marginR54">
              <img src="//file.dhunting.com/official_website/v1/contact-down-code.png" alt="" srcset="">
              <div class="qs_info">官方客服</div>
            </div>
            <div class="qscode_item">
              <img src="//file.dhunting.com/official_website/v1/wechat-code.png" alt="" srcset="">
              <div class="qs_info">微信服务号</div>
            </div>
          </div>
        </div>
        <div class="footer_top_right">
          <div class="contact">联系我们</div>
          <div class="line"></div>
          <div class="contact_info">地址：上海市徐汇云锦路500号绿地·汇中心A座</div>
          <div class="contact_info">邮箱：hr@dhunting.com</div>
          <div class="contact_info">购买咨询微信：Dhuntingddd</div>
        </div>
      </div>
      <div class="footer_bottom">
        <a href="https://beian.miit.gov.cn/" target="_blank">{{recordNumber}}</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      recordNumber: '沪ICP备20002773号-1',
    }
  },
  mounted() {
    this.recordNumber = window.location.href.includes('dhuntingai.com') ? '沪ICP备2025109370号' : '沪ICP备20002773号-1';
  },
}
</script>
<style>
  .footer-wrap{
    width: 100%;
    display: flex;
    padding: 78px 84px 40px 135px;
    justify-content: center;
    align-items: center;
    background: #06173A;
  }
  .footer{
    max-width: 1440Px;
    width: 100%;
  }
  .footer_top{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 54px;
  }
  .footer_title{
    width: 288px;
    height: 56px;
  }
  .footer_title > img{
    width: 100%;
    height: 100%;
  }
  .qscode_wrap{
    margin-top: 53px;
    display: flex;
    justify-content: space-between;
  }
  .qscode_item > img{
    width: 109px;
    height: 109px;
  }
  .qs_info{
    margin-top: 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #FFFFFF;
    text-align: center;
  }
  .marginR54{
    margin-right: 54px;
  }
  .contact{
    font-weight: 500;
    font-size: 36px;
    color: #FFFFFF;
    margin-bottom: 24px;
  }
  .line{
    width: 81px;
    border: 3px solid #FFFFFF;
    border-radius: 15px;
    margin-bottom: 48px;
  }
  .contact_info{
    height: 26px;
    line-height: 26px;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
  }
  .footer_bottom{
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    text-align: center;
  }
  .footer_bottom > a {
    color: #FFFFFF!important;
  }
</style>
